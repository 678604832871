import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

const Chat = () => {
  const [chatHistory, setChatHistory] = useState([]);
  const [message, setMessage] = useState("");
  const chatContainerRef = useRef(null);

  const handleSend = async () => {
    if (message.trim() === "") return;

    // Add user message to chat history
    setChatHistory((prevHistory) => [
      ...prevHistory,
      { type: "user", text: message },
    ]);

    try {
      const res = await axios.post("/api/chat", { message });
      const botResponse = res.data.response;

      // Add bot response to chat history
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { type: "bot", text: botResponse },
      ]);
    } catch (err) {
      console.error(err);
      setChatHistory((prevHistory) => [
        ...prevHistory,
        {
          type: "bot",
          text: "Error: Could not get a response from the chatbot.",
        },
      ]);
    }

    // Clear input field
    setMessage("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSend();
    }
  };

  useEffect(() => {
    // Scroll with new messages
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  return (
    <div className="chat-wrapper">
      <div className="chat-container" ref={chatContainerRef}>
        {chatHistory.map((entry, index) => (
          <div
            key={index}
            className={`chat-bubble ${
              entry.type === "user" ? "user-bubble" : "bot-bubble"
            }`}
          >
            {entry.text.split("\n").map((line, idx) => (
              <React.Fragment key={idx}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
      <div className="input-container">
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Skriv din fråga"
        />
        <button onClick={handleSend}>Skicka</button>
      </div>
    </div>
  );
};

export default Chat;
